<template>
  <div>
    <ManageBanners_3 />
  </div>
</template>
<script>
import ManageBanners_3 from "@/components/Banners_3/ManageBanners_3";
export default {
  components: {
    ManageBanners_3,
  },
  created() {
  },
};
</script>
